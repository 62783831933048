import React from "react";
import { useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import CssBaseline from "@material-ui/core/CssBaseline";
import TextField from "@material-ui/core/TextField";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { useMutation } from "react-query";
import { login } from "../../api/API";
import { useSnackBar } from "../providers/SnackBarProvider";
import CircularProgress from "@material-ui/core/CircularProgress";
import { URL_PARAMS } from "../../constants/Constants";

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      © {new Date().getFullYear()} Būvniecības Valsts Kontroles Birojs. <br />{" "}
      All Rights Reserved
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  formWrapper: {
    width: "100%",
    marginTop: theme.spacing(1),
  },
  signIn: {
    margin: theme.spacing(3, 0, 2),
    padding: "10px 0",
  },
}));

// TODO: create an authentication provider (using React context api) to manage login, logout, changing password, save user info into local storage....
export default function SignIn() {
  const classes = useStyles();
  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const history = useHistory();
  const { showSnackBar } = useSnackBar();
  const [loginMutation, { isLoading }] = useMutation((data) => login(data), {
    onSuccess: (response) => {
      localStorage.setItem("token", response.data?.AccessToken || "");
      localStorage.setItem("userId", response.data?.UserId);
      localStorage.setItem("userName", response.data?.UserName);
      localStorage.setItem("firstName", response.data?.FirstName);
      localStorage.setItem("lastName", response.data?.LastName);
      if (response.data?.MustChangePassword) {
        const passwordChangeMessage = response.data.PasswordChangeMessage || "";
        history.push(
          `/?${URL_PARAMS.mustChangePassword}=true&${URL_PARAMS.passwordChangeMessage}=${passwordChangeMessage}`
        );
      } else history.push("/");
    },
    onError: (error) => {
      showSnackBar({
        severity: 'error',
        text: `kļūda: ${error.response?.data.error}`,
      });
    },
  });

  const onLoginClick = () => {
    loginMutation({
      UserName: username,
      Password: password,
      IPAddress: "127.0.0.1",
    });
  };

  const onEnterClick = (ev) => {
    if (ev.key === "Enter") onLoginClick();
  };

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justify="center"
      style={{ minHeight: "100vh" }}
    >
      <CssBaseline />
      <Grid item>
        <div className={classes.paper}>
          <Typography component="h1" variant="h2" color="primary">
            ALDIS
          </Typography>
          <form className={classes.formWrapper} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Lietotāja vārds"
              autoFocus
              value={username}
              onChange={({ target }) => setUsername(target.value)}
              onKeyPress={onEnterClick}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Parole"
              type="password"
              value={password}
              onChange={({ target }) => setPassword(target.value)}
              onKeyPress={onEnterClick}
            />
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.signIn}
              onClick={onLoginClick}
            >
              {isLoading ? (
                <CircularProgress color="inherit" size={18} />
              ) : (
                  "IENĀKT"
                )}
            </Button>
          </form>
        </div>
      </Grid>
      <Box mt={4}>
        <Copyright />
      </Box>
    </Grid>
  );
}
