import React from 'react'
import Header from '../elements/Header'
import Navigation from '../elements/Navigation'
import { Switch, Route, Redirect } from 'react-router-dom'
import Home from './home/Home'
import Registry from './Registry'
import Box from '@material-ui/core/Box'
import Merchants from './Contracts'
import Classifiers from './classifiers/Classifiers'
import Audits from './Audits'
import Monitoring from './Monitoring'
import DeletedProtectedPersons from './DeletedProtectedPersons'
import Users from './users/Users'
import { MerchantSupport, ALSupport, ALBalance } from './overview'
import { isAdmin } from '../../utils/Misc'

export default function Main() {

  return (
    <Box px={5} mt={1}>
      <Header />
      <Navigation />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/registri" component={Registry} />
        <Route path="/tirgotāji" component={Merchants} />
        <Route path="/klasifikatori" component={Classifiers} />
        <Route path="/datu-atskaite" component={Audits} />
        <Route path="/monitorings" component={Monitoring} />
        <Route path="/dzēstie-lietotāji" component={DeletedProtectedPersons} />
        <Route path="/pārskati/bilance-al" component={ALBalance}/>
        <Route path="/pārskati/atbalsts-al" component={ALSupport} />
        <Route path="/pārskati/atbalsts-tirgotājiem" component={MerchantSupport} />
        {isAdmin() && <Route path="/lietotāju-pārvaldība" component={Users} />}
        <Route path="*" component={() => <Redirect to="/" />} />
      </Switch>
    </Box>
  )
}
