
export function normalizePersonCode(personCode = "") {
  return personCode.replace(/\s/g, "").replace("-", "");
}

export function addDashToPersonCode(personCode = "") {
  if (!personCode) return "";
  if (personCode.includes("-")) return personCode;
  const position = 6;
  return [personCode.slice(0, position), "-", personCode.slice(position)].join(
    ""
  );
}

export function isAdmin() {
  const token = localStorage.getItem('token')
  try {
    const parts = token.split(".")
    const payload = JSON.parse(atob(parts[1]))

    return payload.IsAdmin === true
  } catch (error) {
    return false
  }
}